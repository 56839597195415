import React from "react"
import {useTranslation} from "react-i18next"

import './who-we-are.component.less';

import MainImg from "../../assets/img/who-we-are/main.jpg";
import SmallImg from "../../assets/img/who-we-are/main_small.jpg";
import Stripes from "../../assets/img/who-we-are/stripes.svg";
import Chubarov from "../../assets/img/who-we-are/dima_and_book.jpg";
import Seo from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const WhoWeAreComponent = () => {
    const {i18n, t} = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo description={t("metaDescriptionWhoWeAre")} lang={i18n.language} title={t("titleWhoWeAre")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="who-we-are">
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block">
                    <div
                        className='who-we-are__chapter-name who-we-are__chapter-name--1'>
                        <div
                            className='who-we-are__chapter-name--artefact' />
                        <div className='who-we-are__chapter-name--text'>
                            <span dangerouslySetInnerHTML={{__html: t("ourVision")}} />
                        </div>
                    </div>
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span dangerouslySetInnerHTML={{__html: t("createProducts")}} /> <span className="main-block__title-purple" dangerouslySetInnerHTML={{__html: t("globalAir")}} />
                        </div>
                        <div
                            className="main-block__description main-block__description--single">
                            <strong dangerouslySetInnerHTML={{__html: t("weBelieve")}} /> <span dangerouslySetInnerHTML={{__html: t("thatDiagnostics")}} />
                        </div>

                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={MainImg}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                    className="main-block__small-img"
                                    src={SmallImg}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="padding-top-90 wrapper_block wrapper_block__block wrapper_block__block--no-border title-and-img title-and-img--big-padding">
                    <div className="title-and-img__title">
                        <div
                            className='who-we-are__chapter-name who-we-are__chapter-name--2'>
                            <div
                                className='who-we-are__chapter-name--artefact who-we-are__chapter-name--artefact-yellow'/>
                            <div className='who-we-are__chapter-name--text padding-top-50'>
                                <span dangerouslySetInnerHTML={{__html: t("whatWeDo")}} />
                            </div>
                        </div>
                        <span dangerouslySetInnerHTML={{__html: t("ourInputTo")}} /></div>
                    <div className="title-and-img__img"><img alt="" src={Stripes}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("developAndImplement")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("airQuality")}} /> </strong>
                                    <span dangerouslySetInnerHTML={{__html: t("with")}} />
                                </div>
                                <div className="borders-block__col--point">
                                    <ul>
                                        <li><span dangerouslySetInnerHTML={{__html: t("networksWith")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("classicAnd")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("mathematicAnd")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("wideRange")}} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("develop")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("mathematicalModelling")}} /> </strong>
                                    <span dangerouslySetInnerHTML={{__html: t("ofPollutants")}} />
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <span dangerouslySetInnerHTML={{__html: t("basedOnOur")}} />
                                </div>

                                <div className="borders-block__col--point">
                                    <ul>
                                        <li><span dangerouslySetInnerHTML={{__html: t("provideSuper")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("identifyEmissions")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("determineBackgrounds")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("forecastIndustrial")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("createModels")}} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div
                                    className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("developAndProduce")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("compactStateOfArt")}} /></strong>
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <span dangerouslySetInnerHTML={{__html: t("ourHardware")}} />
                                </div>
                                <div className="borders-block__col--point">
                                    <ul>
                                        <li><span dangerouslySetInnerHTML={{__html: t("highAccuracyAnd")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("fullyAutonomous")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("operationAtWide")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("dataVerificationAnd")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("quickIntegratio")}} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4
                                </div>
                                <div
                                    className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("develop")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("platform")}} /> </strong>
                                    <span dangerouslySetInnerHTML={{__html: t("providingFullAnd")}} />
                                </div>
                                <div
                                    className="borders-block__col--subtitle"><span dangerouslySetInnerHTML={{__html: t("integrateIntoThe")}} />
                                </div>
                                <div className="borders-block__col--point">
                                    <ul>
                                        <li><span dangerouslySetInnerHTML={{__html: t("simplicityAndVisibility")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("extensiveAndEasy")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("quickIntegration")}} /></li>
                                        <li><span dangerouslySetInnerHTML={{__html: t("highestLevelOfData")}} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="padding-top-60 wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border two-col padding-top-6">
                    <div className="two-col__col two-col__col--paragraph">
                        <div
                            className='who-we-are__chapter-name who-we-are__chapter-name--3'>
                            <div
                                className='who-we-are__chapter-name--artefact'/>
                            <div className='who-we-are__chapter-name--text padding-top-50'>
                                <span dangerouslySetInnerHTML={{__html: t("ourValues")}} />
                            </div>
                        </div>
                        <div className="two-col__col-left">
                            <div className="two-col__section">
                                <div
                                    className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("researchAndEngineering")}} /></div>
                            </div>
                            <div className="two-col__section">
                                <div>
                                    <div
                                        className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("openCommunication")}} /> <span dangerouslySetInnerHTML={{__html: t("openCommunication2")}} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="two-col__col-right">
                            <div className="two-col__section">
                                <div
                                    className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("commonSense")}} /></div>
                            </div>
                            <div className="two-col__section">
                                <div
                                    className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("searchOfHuman")}} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="two-col__col two-col__col--description">
                        <div className="two-col__col-left two-col__col-left--non_border two-col__col-left--who-we-are">
                            <div className="two-col__section--description">
                                <span dangerouslySetInnerHTML={{__html: t("weLoveTo")}} />
                                <strong><span dangerouslySetInnerHTML={{__html: t("createUseful")}} /></strong>
                            </div>
                            <div className="two-col__section--description">
                                <span dangerouslySetInnerHTML={{__html: t("weAreHappy")}} />
                                <strong><span dangerouslySetInnerHTML={{__html: t("toTakeGreat")}} /> </strong><span dangerouslySetInnerHTML={{__html: t("and")}} />
                                <strong><span dangerouslySetInnerHTML={{__html: t("engineeringChallenges")}} /></strong>
                            </div>
                            <div className="two-col__section--description">
                                <span dangerouslySetInnerHTML={{__html: t("weStronglyBelieve")}} /><strong> <span dangerouslySetInnerHTML={{__html: t("mankindAnd")}} /></strong>
                            </div>
                            <div className="two-col__section--description">
                                <span dangerouslySetInnerHTML={{__html: t("weAreLooking")}} />
                                <strong><span dangerouslySetInnerHTML={{__html: t("cooperation")}} /> </strong><span dangerouslySetInnerHTML={{__html: t("weCanBeAs")}} />
                            </div>
                        </div>
                        <div
                            className="two-col__col-right two-col__col-right--who-we-are">
                            <img alt="" className="two-col__img" src={Chubarov}/>
                            <div className="two-col__caption">
                                <div><span dangerouslySetInnerHTML={{__html: t("dmitryChubarov")}} /></div>
                                <div><span dangerouslySetInnerHTML={{__html: t("leadOfThe")}} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

WhoWeAreComponent.propTypes = {}

WhoWeAreComponent.defaultProps = {}

export default WhoWeAreComponent
