import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import WhoWeAreComponent from "../components/who-we-are/who-we-are.component"

const WhoWeAre = () => {
  return (
    <Layout>
      <WhoWeAreComponent />
    </Layout>
  )
}

export default WhoWeAre

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
